import React, { useEffect} from 'react'

function BP({title}) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>BP</div>
  )
}

export default BP