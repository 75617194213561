import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './styles/ExternalResources.css'
import external_resouces from '../../assets/json/main/external_resources.json'

function ExternalResources({ title }) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className='external-resources'>
      <div className='hero hero-external'>
        <h1 className='pt-sm-5 pt-4 text-center'>{title}</h1>
      </div>
      <div className="py-3 px-lg-5 px-md-3 px-sm-1">
        <ul className='text-white external-link'>
          {external_resouces.map((item) => (
            <>
              <li><h5>{item.title} <Link to={item.link} target='_blank' className='text-decoration-none'> <span className='d-none d-sm-inline'>{item.link} <sup><i className="fa fa-external-link"></i></sup></span> <i className="fa fa-external-link d-inline d-sm-none"></i></Link></h5></li>
              <br />
            </>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ExternalResources