import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/main/Header';
import Home from './components/main/Home';
import About from './components/main/About';
import LectureNotes from './components/main/LectureNotes';
import ProblemBank from './components/main/ProblemBank';
import ExternalResources from './components/main/ExternalResources';
import Blog from './components/main/Blog';
import Contact from './components/main/Contact';
import TheoreticalPhysics from './components/blog/TheoreticalPhysics';
import NuclearPhysics from './components/blog/NuclearPhysics';
import ComplexSystems from './components/blog/ComplexSystems';
import BioPhysics from './components/blog/BioPhysics';
import AstroPhysics from './components/blog/AstroPhysics';
import Interdisciplinary from './components/blog/Interdisciplinary';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/home' element={<Home title='Home' />} />
          <Route path='/about' element={<About title='About' />} />
          <Route path='/lecture-notes' element={<LectureNotes title='Lecture Notes' />} />
          <Route path='/problem-bank' element={<ProblemBank title='Problem Bank' />} />
          <Route path='/external-resources' element={<ExternalResources title='External Resources' />} />
          <Route path='/blog' element={<Blog title='Blog' />} />
          <Route path='/contact' element={<Contact title='Contact' />} />

          <Route path='/blog/theoretical-physics' element={<TheoreticalPhysics title='Blog - Theoretical Physics' />} />
          <Route path='/blog/nuclear-particle-physics' element={<NuclearPhysics title='Blog - Nuclear & Particle Physics' />} />
          <Route path='/blog/complex-systems' element={<ComplexSystems title='Blog - Complex Systems' />} />
          <Route path='/blog/biophysics' element={<BioPhysics title='Blog - Biophysics' />} />
          <Route path='/blog/astrophysics' element={<AstroPhysics title='Blog - Astrophysics' />} />
          <Route path='/blog/interdisciplinary' element={<Interdisciplinary title='Blog - Interdisciplinary' />} />

          <Route path='*' element={<Navigate to='/home' replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
