import React, {useEffect} from 'react'

function CS({title}) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>CS</div>
  )
}

export default CS