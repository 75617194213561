import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './styles/Blog.css'
import blog from '../../assets/json/blog/blog.json'

function Blog({ title }) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="blog">
      <div className='hero hero-blog'>
        <h1 className='pt-sm-5 pt-4'>{title}</h1>
      </div>
      <div className='container-fluid px-3 px-md-5 pb-3'>
        <div className='row'>
          {blog.map((item) => (
            <div key={item.id} className='col-lg-4 col-sm-6 col-12 pt-3'>
              <div className='card bg-transparent text-white border'>
                <div className='card-body'>
                  <h5 className='card-title'>{item.title}</h5>
                  <p className='card-text'>
                    {item.desc.substring(0, 40)} ...
                  </p>
                  <Link to={item.link} className='btn btn-warning'>
                    Go &rarr;
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Blog