import { useState } from 'react';
import { Link } from 'react-router-dom'
import './styles/Header.css'
import profile from '../../assets/img/profile.png'
import header from '../../assets/json/main/header.json'

function Header() {
  const [show, setshow] = useState(false);

  return (
    <div className='header fixed-top bg-black'>

      <nav className='navbar navbar-expand-lg'>
        <div className='container-fluid'>
          <Link className='navbar-brand header-name d-flex align-items-center text-white' to='/home' onClick={() => setshow(false)}>
            <img src={profile} className='header-profile rounded-circle' alt='' /> &nbsp;
            SciPool
          </Link>

          {/* hamburger for phone */}
          <button className='bg-warning rounded d-md-none' type='button' onClick={() => setshow(!show)}>
            <span className='navbar-toggler-icon'></span>
          </button>
          {/* hamburger for phone */}

          {/* menu for tablet and desktop */}
          <div className='header-div d-none d-md-block' id='navbarSupportedContent'>
            <ul className='navbar-nav d-flex flex-row me-auto mb-2 mb-lg-0'>
              {header.map((item) => (
                <li className='nav-item px-lg-2 px-1' key={item.id}>
                  <Link className='nav-link header-light text-white' to={item.link}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* menu for phone */}
        {show && <div className='header-div mx-3 d-md-none' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            {header.map((item) => (
              <li className='nav-item' key={item.id}>
                <Link className='nav-link text-white' to={item.link} onClick={() => setshow(!show)}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>}
      </nav>

    </div>
  )
}

export default Header
