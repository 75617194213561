import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './styles/About.css'

function About({title}) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="about">
      <div className='hero hero-about'>
        <h1 className='pt-sm-5 pt-4'>{title}</h1>
      </div>
      <div className="container py-3 px-lg-5 px-3">
        <div className="row">
          <div className="col-12 text-light d-flex justify-content-center">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, tempore. Natus dolor eveniet doloribus nam in inventore, sequi quam! Mollitia velit dignissimos perspiciatis, nesciunt quas quisquam soluta! Architecto, sunt cum!
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero maiores dolore recusandae qui sequi deserunt magni! Aperiam consequuntur quos, mollitia dolorum culpa sunt nobis architecto earum dicta harum rem est! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi recusandae corrupti, nulla sunt itaque perferendis expedita repellat est illo tempora. Dolore veniam accusantium ratione soluta atque earum modi labore autem in? Voluptates perspiciatis illum accusamus facere, fugit aliquam, alias cumque, animi officia maxime deserunt provident. Id qui dolore magnam quo maiores. Repellat architecto aliquam vitae est nobis incidunt rem, ab praesentium maiores adipisci, consequuntur perferendis commodi saepe quas possimus. Alias molestias corrupti omnis a repudiandae? Dolore, in. Ipsum facilis reiciendis deleniti, quia repellat minima quasi eveniet dolores eius obcaecati laudantium harum dolore dolorum dolorem modi consequatur. Tenetur repudiandae, error molestiae, fuga tempore nemo repellat vitae, qui asperiores natus quaerat eaque. Aliquid facilis doloribus, vero fugiat culpa nemo mollitia fuga consequatur nesciunt eaque ullam accusamus at nostrum id error accusantium et! Amet dolores tenetur voluptatibus aperiam tem.
            </p>
          </div>
          <div className="col-12 text-light d-flex mt-3 justify-content-center">
            <Link to='/home'><button className='btn btn-warning'>Explore &rarr;</button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About