import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import './styles/LectureNotes.css'
import lecture_notes from '../../assets/json/main/lecture_notes.json'
import all_courses from '../../assets/json/lecture_notes/all_courses.json'
import pdfFile from '../../assets/sample.pdf'

function LectureNotes({ title }) {

  const pathname = useLocation()
  const [hash, sethash] = useState(pathname.hash)

  useEffect(() => {
    document.title = title;
    sethash(pathname.hash)
  }, [pathname.hash, title])

  useEffect(() => {
    document.getElementById('expand').scrollIntoView({ behavior: "smooth" })
  }, [hash])

  function scroll() {
    document.getElementById('expand').scrollIntoView({ behavior: "smooth" })
  }

  function download_file(fileURL, fileName) {
    if (!window.ActiveXObject) {
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
      save.download = fileName || filename;
      if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent
        .search("Chrome") < 0) {
        document.location = save.href;
      } else {
        var evt = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, '_blank');
      _window.document.close();
      _window.document.execCommand('SaveAs', true, fileName || fileURL)
      _window.close();
    }
  }

  return (
    <div className="lecture-notes">
      <div className='hero hero-lecture'>
        <h1 className='pt-sm-5 pt-4'>{title}</h1>
      </div>

      <table className='table border border-white rounded text-white m-auto'>
        <thead>
          <tr>
            <th className='text-center'>Undergraduate</th>
            <th className='text-center'>Advanced Undergraduate and Postgraduate</th>
          </tr>
        </thead>
        <tbody>
          {lecture_notes.map((item) => (
            <tr className='child-tr' key={item.id}>
              {item.rows.map((column) => (
                <td key={column.title}>
                  <h5><a onClick={scroll} className={`${hash.includes(column.href) && 'text-warning'} text-decoration-none`} href={column.href}>{column.title}</a></h5>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      
      <div id='expand'>
        {all_courses.map((item) => (
          <div key={item.id} id={item.url} className={`container-fluid mx-sm-3 px-md-5 py-5 ${hash.includes(item.url) ? 'd-block' : 'd-none'}`}>
            <div className='row'>
              {item.loop.map((element) => (
                <div key={element.id} className='col-lg-4 col-sm-6 col-12 pt-3 my-3'>
                  <div className='card bg-transparent text-white border-0'>
                    <div className='card-body'>
                      <h5 className='card-title'>{element.title}</h5>
                      <p className='card-text'>
                        <b>Lecturer:</b> {element.lecturer} <br /> <b>{element.tutors.includes(',') ? 'Tutors' : 'Tutor'}:</b> {element.tutors} <br /> <b>Lectures:</b> <a onClick={scroll} className={`${hash.includes(element.href) && 'text-warning'}`} href={element.href}>Link to the resource</a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='text-center my-5'>
              <Link to='/lecture-notes'><button className='btn btn-warning'>Clear</button></Link>
            </div>
            <div className={`text-center ${hash.includes('pdf') ? 'd-block' : 'd-none'}`}>
              <button className='btn btn-warning' onClick={() => download_file(pdfFile, 'sample.pdf')}>Download PDF</button>
            </div>
          </div>
        ))}
      </div>

      <div className={`text-center mb-5 ${hash.includes('pdf') ? 'd-block' : 'd-none'}`}>
        <iframe
          src={pdfFile}
          type="application/pdf"
          height={800}
          className='w-75'
          title='pdfview'
        />
      </div>
    </div>
  )
}

export default LectureNotes