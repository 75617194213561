import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import './styles/ProblemBank.css'
import problem_bank from '../../assets/json/main/problem_bank.json'
import all_courses from '../../assets/json/lecture_notes/all_courses.json'
import pdfFile from '../../assets/sample.pdf'

function ProblemBank({ title }) {

  const pathname = useLocation()

  const [hash, sethash] = useState(pathname.hash)

  useEffect(() => {
    document.title = title;
    sethash(pathname.hash)
  }, [pathname.hash, title])

  useEffect(() => {
    document.getElementById('expand').scrollIntoView({ behavior: "smooth" })
  }, [hash])

  function scrollPdf() {
    document.getElementById('expand').scrollIntoView({ behavior: "smooth" })
  }

  function download_file(fileURL, fileName) {
    if (!window.ActiveXObject) {
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
      save.download = fileName || filename;
      if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent
        .search("Chrome") < 0) {
        document.location = save.href;
      } else {
        var evt = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, '_blank');
      _window.document.close();
      _window.document.execCommand('SaveAs', true, fileName || fileURL)
      _window.close();
    }
  }

  return (
    <div className="problembank">
      <div className='hero hero-problem'>
        <h1 className='pt-sm-5 pt-4'>{title}</h1>
      </div>

      <div className="py-3 px-lg-5 px-sm-3 d-flex flex-column flex-sm-row">
        <ul className='text-white problem-bank py-2' id='expand'>
          {problem_bank.map((item) => (
            <>
              <li key={item.id}><h5><a className={`${hash.includes(item.href) && 'text-warning'} text-decoration-none`} href={item.href}>{item.title}</a></h5></li>
              <br />
            </>
          ))}
        </ul>
        {all_courses.map((item) => (
          <div key={item.id} className={`container-fluid mx-sm-3 px-md-5 ${hash.includes(item.url) ? 'd-block' : 'd-none'}`}>
            <div className='row'>
              {item.loop.map((element) => (
                <div key={element.id} className='col-xl-6 col-12 mb-5 pb-lg-0'>
                  <div className='card bg-transparent text-white border-0'>
                    <div className='card-body'>
                      <h5 className='card-title'>{element.title}</h5>
                      <p className='card-text'>
                        <b>Lecturer:</b> {element.lecturer} <br /> <b>{element.tutors.includes(',') ? 'Tutors' : 'Tutor'}:</b> {element.tutors} <br /> <b>Exercises:</b> <a onClick={scrollPdf} className={`${hash.includes(element.href) && 'text-warning'}`} href={element.href}>Link to the resource</a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='text-center my-5'>
              <Link to='/problem-bank'><button className='btn btn-warning'>Clear</button></Link>
              <button className={`btn btn-warning mx-5 ${hash.includes('pdf') ? 'd-inline' : 'd-none'}`} onClick={() => download_file(pdfFile, 'sample.pdf')}>Download PDF</button>
            </div>
          </div>
        ))}
      </div>

      <div id="pdfView" className='d-flex'>
        <div className="col-4"></div>
        <div className={`mb-5 col-8 text-sm-center w-75 ${hash.includes('pdf') ? 'd-block' : 'd-none'}`}>
          <iframe
            src={pdfFile}
            type="application/pdf"
            height={800}
            className="w-75"
            title='pdfview'
          />
        </div>
      </div>
    </div>
  )
}

export default ProblemBank