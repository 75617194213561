import React, {useEffect} from 'react'

function NP({title}) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>NP</div>
  )
}

export default NP