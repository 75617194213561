import React, { useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser';
import './styles/Contact.css'

function Contact({ title }) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  const form = useRef();

  function submitForm(event) {
    event.preventDefault();
    emailjs.sendForm('service_gycfy8e', 'template_byn1zn8', form.current, 'XP7QD7ZEM3HhfPx0M')
      .then(() => {
        document.getElementById('formId').reset()
        alert('Thank you for submitting!')
      }, (error) => {
        console.log(error.text);
      });
  }

  return (
    <div className="contact">
      <div className='hero hero-contact'>
        <h1 className='pt-sm-5 pt-4'>{title}</h1>
      </div>
      <div className="form row m-auto mb-3">
        <form ref={form} onSubmit={submitForm} id='formId' className='text-light'>
          <h2 className='text-center'>Send your query here</h2>
          <div className="d-flex form-details row mt-5">
            <div className="mb-3 col-5 col-sm-4">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" name='user_name' className="form-control" id="name" required />
            </div>
            <div className="mb-3 col-7 col-sm-8">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" name='user_email' className="form-control" id="email" required />
            </div>
          </div>
          <div className="mb-3 col-12">
            <label htmlFor="subject" className="form-label">Subject</label>
            <input type="text" name='subject' className="form-control" id="subject" required />
          </div>
          <div className="mb-3 col-12">
            <label htmlFor="comment" className="form-label">Comment</label>
            <textarea type="text" name='comment' className="form-control" id="comment" required />
          </div>
          <button type="submit" className="btn btn-warning">Submit</button>
        </form>
      </div>
    </div>
  )
}

export default Contact