import React, {useEffect} from 'react'

function AP({title}) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>AP</div>
  )
}

export default AP