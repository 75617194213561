import React, { useEffect } from 'react'

function Inter({title}) {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>Inter</div>
  )
}

export default Inter