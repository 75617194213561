import React, {useEffect} from 'react'

function TP({title}) {
   
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>TP</div>
  )
}

export default TP